import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const RightDivider = styled.div`
  text-align: right;
`;

export const Name = styled.div`
  font-weight: 700;
`;
export const Confirmed = styled.div`
  color: ${({ theme }) => theme.colors.green};
  font-size: 0.75rem;
  font-weight: 700;
`;
export const SharedBtn = styled.button`
  padding: 0.3rem 1rem;
  font-size: 0.75rem;
  margin-top: 0.3rem;
  white-space: pre-wrap;
`;
export const TitleWrapper = styled.div`
  margin-left: 0.75rem;
`;
export const Title = styled.h5`
  margin: 0;
  line-height: 1;
  font-weight: 700;
  max-width: 250px;
`;

export const SubTitle = styled.div`
  font-size: 0.85rem;
  font-weight: 600;
`;

export const Heading = styled.h6`
  font-weight: 700;
`;
export const Logo = styled.img`
  border-radius: 1rem;
  width: 50px;
  height: 50px;
  object-fit: cover;
`;
export const Categories = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100vw;
  overflow-x: scroll;
  padding: 0.5rem 0.75rem 0;

  span {
    display: flex;
  }

  a:not(:last-child) {
    margin-right: 0.75rem;
  }
`;
