import moment from 'moment';
import { MINUTE } from '../pages/Order/Form/Form';

export type InputObject = {
  username?: string;
  email?: string;
  password?: string;
  repeatPassword?: string;
  phone?: string;
  name?: string;
  lastname?: string;
  price?: number;
  card?: string;
  tag?: string;
  content?: string;
  message?: string;
  deliveryAt?: string;
  other?: string;
  array?: string[];
};

export type Messages = {
  isValid: boolean;
} & { [key in keyof InputObject]: string };

export type ConfigValidation = {
  optional: (keyof InputObject)[];
  deliveryTime: number;
};

const defaultConfig: ConfigValidation = {
  optional: [],
  deliveryTime: 90,
};

export const validation =
  (config: ConfigValidation = defaultConfig) =>
  (object: InputObject): Messages => {
    let messages: Messages = { isValid: false };
    let isValidate;

    const username = object.username?.trim();
    const name = object.name;
    const lastname = object.lastname;
    const email = object.email?.trim().toLowerCase();
    const password = object.password;
    const repeatPassword = object.repeatPassword;
    const phone = object.phone;
    const content = object.content;
    const message = object.message;
    const other = object.other;
    const array = object.array;
    const price = object.price;
    const card = object.card;
    const tag = object.tag;
    const deliveryAt = object.deliveryAt;

    if (object.hasOwnProperty('username')) {
      if (!username) {
        if (config.optional.indexOf('username') < 0) {
          messages.username = 'Заполните поле Логин!';
          isValidate = false;
        }
      } else if (username.length < 4) {
        messages.username = 'Логин должен быть длиннее 4 знаков!';
        isValidate = false;
      } else if (username.length > 12) {
        messages.username = 'Логин должен быть короче 12 знаков!';
        isValidate = false;
      } else if (
        !/\w/.test(username) &&
        !/\p{Script=Cyrillic}+/u.test(username)
      ) {
        messages.username = 'Логин должен содержать буквы/числа!';
        isValidate = false;
      } else {
        messages.username = 'isValid';
      }
    }

    if (object.hasOwnProperty('name')) {
      if (!name) {
        if (config.optional.indexOf('name') < 0) {
          messages.name = 'Заполните поле Имя!';
          isValidate = false;
        }
      } else if (!/\p{Script=Cyrillic}/u.test(name)) {
        messages.name = 'Имя должно содержать буквы!';
        isValidate = false;
      } else if (name.length > 99) {
        messages.name = 'Имя должно быть короче 100 знаков!';
        isValidate = false;
      } else {
        messages.name = 'isValid';
      }
    }

    if (object.hasOwnProperty('lastname')) {
      if (!lastname) {
        if (config.optional.indexOf('lastname') < 0) {
          messages.lastname = 'Заполните поле Фамилия!';
          isValidate = false;
        }
      } else if (
        !/\w/.test(lastname) &&
        !/\p{Script=Cyrillic}/u.test(lastname)
      ) {
        messages.lastname = 'Фамилия должна содержать буквы!';
        isValidate = false;
      } else if (lastname.length > 99) {
        messages.lastname = 'Фамилия должна быть короче 100 знаков!';
        isValidate = false;
      } else {
        messages.lastname = 'isValid';
      }
    }

    if (object.hasOwnProperty('email')) {
      if (!email) {
        if (config.optional.indexOf('email') < 0) {
          messages.email = 'Заполните поле Email!';
          isValidate = false;
        }
      } else if (!/@/.test(email)) {
        messages.email = 'Email должен содержать знак @!';
        isValidate = false;
      } else if (!/^[\w_.-]+@/.test(email)) {
        messages.email = 'Email должен содержать знаки до @!';
        isValidate = false;
      } else if (!/@(\w)+/.test(email)) {
        messages.email = 'Email должен содержать знаки после @!';
        isValidate = false;
      } else if (!/(\.+\w{2,6})$/.test(email)) {
        messages.email = 'Email должен содержать домен!';
        isValidate = false;
      } else if (email.length > 200) {
        messages.email = 'Email должен быть короче 201 знака!';
        isValidate = false;
      } else {
        messages.email = 'isValid';
      }
    }

    if (object.hasOwnProperty('password')) {
      if (!password) {
        if (config.optional.indexOf('password') < 0) {
          messages.password = 'Заполните поле Пароль!';
          isValidate = false;
        }
      } else if (password.length < 8) {
        messages.password = 'Пароль должен быть длиннее 6 знаков!';
        isValidate = false;
      } else if (!/\w/.test(password)) {
        messages.password = 'Пароль должен содержать буквы/числа!';
        isValidate = false;
      } else if (password.length > 64) {
        messages.password = 'Пароль должен быть короче 64 знаков!';
        isValidate = false;
      } else {
        messages.password = 'isValid';
      }
    }

    if (object.hasOwnProperty('repeatPassword')) {
      if (repeatPassword === password) {
        messages.repeatPassword = 'isValid';
      } else {
        messages.repeatPassword = 'Пароли не совпадают!';
        isValidate = false;
      }
    }

    if (object.hasOwnProperty('phone')) {
      if (!phone) {
        if (config.optional.indexOf('phone') < 0) {
          messages.phone = 'Заполните поле Номер телефона!';
          isValidate = false;
        }
      } else if (!/\d/.test(phone) || phone.match(/\d/g)!.length !== 11) {
        messages.phone = 'Номер должен содержать 10 чисел!';
        isValidate = false;
      } else {
        messages.phone = 'isValid';
      }
    }

    if (object.hasOwnProperty('card')) {
      if (!card) {
        if (config.optional.indexOf('card') < 0) {
          messages.card = 'Введите номер банковской карты!';
          isValidate = false;
        }
      } else if (!/\d/.test(card) || card.match(/\d/g)!.length !== 16) {
        messages.card = 'Номер должен содержать 16 чисел!';
        isValidate = false;
      } else {
        messages.card = 'isValid';
      }
    }

    if (object.hasOwnProperty('tag')) {
      if (!tag) {
        if (config.optional.indexOf('tag') < 0) {
          messages.tag = 'Введите свой игровой тэг!';
          isValidate = false;
        }
      } else if (!/#/.test(tag)) {
        messages.tag = 'Тэг должен содержать #!';
        isValidate = false;
      } else if (tag.length > 10 || tag.length < 8) {
        messages.tag = 'Тэг должен содержать от 8 до 10 символов!';
        isValidate = false;
      } else {
        messages.tag = 'isValid';
      }
    }

    if (object.hasOwnProperty('content')) {
      if (!content) {
        if (config.optional.indexOf('content') < 0) {
          messages.content = 'Заполните поле!';
          isValidate = false;
        }
      } else if (content.length > 10000) {
        messages.content = 'Поле должно содержать не более 10000 знаков!';
        isValidate = false;
      } else if (content.length < 10) {
        messages.content = 'Поле должно содержать минимум 10 знаков!';
        isValidate = false;
      } else {
        messages.content = 'isValid';
      }
    }

    if (object.hasOwnProperty('message')) {
      if (!message) {
        if (config.optional.indexOf('message') < 0) {
          messages.message = 'Заполните поле!';
          isValidate = false;
        }
      } else if (message.length > 1000) {
        messages.message = 'Поле должно содержать не более 1000 знаков!';
        isValidate = false;
      } else {
        messages.message = 'isValid';
      }
    }

    if (object.hasOwnProperty('deliveryAt')) {
      if (!deliveryAt) {
        if (config.optional.indexOf('deliveryAt') < 0) {
          messages.deliveryAt = 'Заполните поле!';
          isValidate = false;
        }
      } else if (!moment(deliveryAt).isValid()) {
        messages.deliveryAt = 'Некорректный формат даты!';
        isValidate = false;
      } else if (
        new Date(deliveryAt).getTime() <
        Date.now() + MINUTE * config.deliveryTime
      ) {
        messages.deliveryAt = `Минимальное время доставки ${config.deliveryTime} минут!`;
        isValidate = false;
      } else {
        messages.deliveryAt = 'isValid';
      }
    }

    if (object.hasOwnProperty('other')) {
      if (!other) {
        if (config.optional.indexOf('other') < 0) {
          messages.other = 'Заполните поле!';
          isValidate = false;
        }
      } else if (other.length > 100) {
        messages.other = 'Поле должно содержать не более 100 знаков!';
        isValidate = false;
      } else {
        messages.other = 'isValid';
      }
    }

    if (object.hasOwnProperty('array')) {
      if (!array || !array.length) {
        if (config.optional.indexOf('array') < 0) {
          messages.array = 'Заполните поле!';
          isValidate = false;
        }
      } else if (
        !/\w/.test(array.join('')) &&
        !/\p{Script=Cyrillic}/u.test(array.join(''))
      ) {
        messages.array = 'Поле должно содержать буквы/числа/символы!';
        isValidate = false;
      } else if (array.join('').length > 100) {
        messages.array = 'Поле должно содержать не более 100 знаков!';
        isValidate = false;
      } else {
        messages.array = 'isValid';
      }
    }

    if (object.hasOwnProperty('price')) {
      if (!price) {
        if (config.optional.indexOf('price') < 0) {
          messages.price = 'Заполните поле цены!';
          isValidate = false;
        }
      } else if (String(price).length > 32) {
        messages.price = 'Поле должно содержать не более 32 чисел!';
        isValidate = false;
      } else {
        messages.price = 'isValid';
      }
    }

    messages.isValid = isValidate === undefined;

    return messages;
  };
