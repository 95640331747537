import styled from 'styled-components';

export const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 3rem;
`;

export const SkeletonContainer = styled.div`
  > span:last-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    //  gap: 12px;

    > span {
      flex-grow: 1;
      max-width: 44vw;
      margin-bottom: 12px;
    }

    span:nth-child(even) {
      margin-right: 12px;
    }
  }
`;
export const NoAppDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 1rem;
  border-radius: 1.5rem;
  background-color: var(--tg-theme-bg-color);
  padding: 1.5rem;
`;

export const NoAppText = styled.div`
  font-weight: 700;
  font-size: 1.15rem;
  margin-bottom: 2rem;
`;

export const NoAppLink = styled.a`
  background: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
  padding: 0.75rem 3rem;
  border-radius: 1rem;
  text-decoration: none;
`;
