import styled from 'styled-components';
import { getTransition } from '../../styles/utilites';

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

type FiltersButtonProps = {
  $isOpen: boolean;
};
export const FiltersButton = styled.button<FiltersButtonProps>`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--tg-theme-text-color);
  background-color: ${({ $isOpen }) =>
    $isOpen ? 'var(--tg-theme-bg-color)' : 'transparent'};
  border-radius: 0;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  padding: 0.5rem;
  white-space: nowrap;
  // margin-bottom: ${({ $isOpen }) => ($isOpen ? '0.75rem' : 0)};
  color: var(--tg-theme-text-color);
  box-shadow: ${({ $isOpen }) =>
    $isOpen ? '0 5px 5px hsla(0, 0%, 0%, 0.5)' : 'none'};

  ${getTransition(300, ['background-color', 'box-shadow'])}
  > div:last-child {
    font-size: 20px;
    margin-bottom: 3px;
    margin-left: 0.5em;
    transform: rotateZ(${({ $isOpen }) => ($isOpen ? '90deg' : 0)});
    color: ${({ $isOpen }) =>
      $isOpen ? 'var(--tg-theme-button-color)' : 'var(--tg-theme-text-color)'};

    ${getTransition(300, 'transform')}
  }
`;
