import styled, { css } from 'styled-components';
import { getTransition } from '../../styles/utilites';

type WrapperProps = {
  $isOpen: boolean;
  $isJsxIn?: boolean;
  $widthInRem?: number;
  $direction: 'top' | 'bottom';
};

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  z-index: ${({ theme }) => theme.order.height};
  display: block;
  overflow: hidden;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};

  padding: 0.25rem 0;
  //padding: ${({ $isJsxIn }) => ($isJsxIn ? 0 : '0.5rem 1rem')};
  background-color: var(--tg-theme-bg-color);
  border-radius: 1rem;
  box-shadow: 0 5px 5px hsla(0, 0%, 0%, 0.5);

  ${({ $direction }) =>
    $direction === 'bottom'
      ? css`
          top: 90%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        `
      : css`
          bottom: 90%;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `}
  left: 0;
  right: 0;

  background-clip: padding-box;

  border: none;
  ${getTransition(300, ['opacity', 'visibility', 'transform'])}
`;
export const Container = styled.ul`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  text-align: center;
  list-style: none;

  li {
    cursor: pointer;
    padding: 0.5em 1em;
  }
`;
