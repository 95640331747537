import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type HeadingProps = {
  $isFirst: boolean;
};
export const Heading = styled.h4<HeadingProps>`
  text-align: left;
  margin: 2.5rem 0 0.75rem;
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;
  ${({ $isFirst }) =>
    $isFirst &&
    css`
      margin-top: 0;
    `}
`;
export const CardsContainer = styled.ul`
  margin: auto;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
`;
