import { useToggle } from '../../hooks/useToggle';
import { MutableRefObject, useRef } from 'react';
import { useClickOutside } from '../../hooks/useClickOutside';
import * as S from './styles';
import { Dropdown } from '../Dropdown/Dropdown';

type SelectionProps = {
  currentId?: string;
  setValue: (id: string) => void;
  items: {
    id: string;
    content: string;
  }[];
};
export const Selection = ({ currentId, setValue, items }: SelectionProps) => {
  const [isMenuOpen, setMenuOpen] = useToggle();

  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(setMenuOpen(false), ref);

  const currentItem = items.find((item) => item.id === currentId);

  return (
    <S.SelectWrapper ref={ref}>
      <S.FiltersButton
        $isOpen={isMenuOpen}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setMenuOpen()();
        }}
      >
        <div>{currentItem ? currentItem.content : 'Не выбран'}</div>
        <div className={'icon-keyboard_arrow_right'} />
      </S.FiltersButton>
      <Dropdown
        direction={'bottom'}
        isOpen={isMenuOpen}
        array={items
          .filter((item) => item.id !== currentId)
          .map(({ id, content }) => ({
            content,
            action: () => {
              setValue(id);
              setMenuOpen(false)();
            },
          }))}
      />
    </S.SelectWrapper>
  );
};
