import * as S from './styles';
import { CartButton } from '../../../components/CartButton/CartButton';
import { useTelegram } from '../../../hooks/useTelegram';
import { Product } from '../../../gql/graphql';

type FixedButtonProps = {
  product?: Product;
  loading: boolean;
};
export const FixedButton = ({ product, loading }: FixedButtonProps) => {
  const { user } = useTelegram();

  return (
    <S.Wrapper>
      <S.ButtonDivider>
        <CartButton
          product={product}
          isNoApp={!user}
          btnText={loading ? 'Загрузка...' : `В корзину за ${product?.price}₽`}
        />
      </S.ButtonDivider>
    </S.Wrapper>
  );
};
