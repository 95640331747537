import { ITheme } from '../interfaces/styled';

export const baseTheme: ITheme = {
  colors: {
    green: 'hsl(119,99%,35%)', //#04B401
  },
  media: {
    sm: '(min-width: 576px)',
    md: '(min-width: 700px)',
    minAnimate: '(prefers-reduced-motion: reduce)',
  },
  durations: {},
  order: {
    minus: -1,
    one: 1,
    two: 2,
    three: 3,
    fixed: 20,
    height: 1000,
    action: 1100,
  },
  opacity: {
    main: 0.66,
  },
};
