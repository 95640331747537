import * as S from './styles';
import { WEB_APP } from '../../constants/constants';
import { Product } from '../../gql/graphql';
import { ProductFlatten } from '../../pages/Main/Positions/Products/ProductGroup/ProductGroup';
import { useEffect, useState } from 'react';
import { useCart } from '../../hooks/useCart';
import { CartItem } from '../../providers/Cart';

type CartButtonProps = {
  product?: Product | ProductFlatten;
  btnText: string;
  isNoApp: boolean;
  isCard?: boolean;
  isCart?: boolean;
};
export const CartButton = ({
  isNoApp,
  btnText,
  product,
  isCard,
  isCart,
}: CartButtonProps) => {
  const { cart, makeChanges } = useCart();
  const restaurant = !!localStorage.getItem('restaurantId')
    ? (localStorage.getItem('restaurantId') as string)
    : '1';
  const [existItem, setExistItem] = useState<CartItem | undefined>(undefined);

  const onAdd = () => {
    if (!product) return;

    const item: CartItem = {
      id: product.id,
      price: product.price,
      count: 1,
    };
    let newItems: CartItem[];
    if (restaurant in cart) {
      if (existItem) {
        item.count = existItem.count + 1;
        newItems = cart[restaurant].map((i) =>
          i.id === product.id ? item : i,
        );
      } else {
        newItems = [...cart[restaurant], item];
      }
    } else {
      newItems = [item];
    }
    setExistItem(item);
    makeChanges(restaurant, newItems, !isCart);
  };
  const onRemove = () => {
    if (!product || !existItem) return;

    let newItems: CartItem[];
    if (existItem.count > 1) {
      const newItem = {
        ...existItem,
        count: existItem.count - 1,
      };
      newItems = cart[restaurant].map((i) =>
        i.id === product.id ? newItem : i,
      );
      setExistItem(newItem);
    } else if (isCart) {
      return;
    } else {
      newItems = cart[restaurant].filter((i) => i.id !== product.id);
      setExistItem(undefined);
    }
    makeChanges(restaurant, newItems, !isCart);
  };

  useEffect(() => {
    setExistItem(
      restaurant in cart && product
        ? cart[restaurant].find((i) => i.id === product.id)
        : undefined,
    );
  }, [restaurant, product]);

  // useEffect(() => {
  //   if (!isCard && !isCart) changeMainBtn(cart[restaurant]);
  // }, []);

  return (
    <>
      {isNoApp && process.env.NODE_ENV !== 'development' ? (
        <S.NoAppLink $isCard={!!isCard} href={WEB_APP}>
          В приложение
        </S.NoAppLink>
      ) : !!existItem ? (
        <S.InCartDivider $isCart={!!isCart}>
          <S.Minus onClick={onRemove} $isCard={!!isCard} $isCart={!!isCart}>
            <span className={'icon-minus'} />
          </S.Minus>
          <S.Count>{existItem.count}</S.Count>
          <S.Plus onClick={onAdd} $isCard={!!isCard} $isCart={!!isCart}>
            <span className={'icon-plus'} />
          </S.Plus>
        </S.InCartDivider>
      ) : (
        <S.Button $isCard={!!isCard} onClick={onAdd}>
          {btnText}
        </S.Button>
      )}
    </>
  );
};
