import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 1.75rem;
  right: 1.75rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and ${({ theme }) => theme.media.md} {
    position: absolute;
  }
`;

export const ControlButton = styled.div`
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-subtitle-text-color);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-bottom: 0.75rem;
  cursor: pointer;
  box-shadow: 0 0 6px
    color-mix(in srgb, var(--tg-theme-text-color), transparent 80%);
  display: flex;
  align-content: center;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
