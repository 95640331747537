'use client';
import styled, { css } from 'styled-components';
import { getTransition } from '../../../styles/utilites';

type ContainerProps = {
  $isVisible: boolean;
};
export const Container = styled.span<ContainerProps>`
  ${({ $isVisible }) =>
    $isVisible
      ? css`
          transform: translateY(0);
        `
      : css`
          transform: translateY(10rem);
        `};
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  z-index: ${({ theme }) => theme.order.fixed};
  cursor: pointer;
  ${getTransition(300, ['transform', 'box-shadow'], 'ease-out')}
  padding: .5em .5em .35em;
  border-radius: 0.3em;
  background-color: var(--tg-theme-bg-color);
  box-shadow: 0 1px 10px 2px hsla(0, 0%, 0%, 0.2);
  font-size: 1.75rem;

  &:hover {
    box-shadow: 0 1px 10px 6px hsla(0, 0%, 0%, 0.2);
  }
`;
