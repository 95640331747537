import styled from 'styled-components';

export const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 1em;
  padding: 0.35em;
  background-color: var(--tg-theme-bg-color);
  width: 47%;
  max-width: 50%;
  flex: 1 1 45%;
  box-shadow: 0 2px 4px 1px
    color-mix(in srgb, var(--tg-theme-text-color), transparent 80%);

  img {
    width: 100%;
    max-height: 220px;
    height: calc(45vw - 28px);
    border-radius: 12px;
  }

  @media screen and ${({ theme }) => theme.media.sm} {
    padding: 0.65em;
  }
`;

export const Name = styled.div`
  text-align: left;
  height: 38px;
  display: -webkit-box;
  overflow: hidden;
  font-size: 1.1rem;
  margin-top: 0.75rem;
  line-height: 1.1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
