import { InMemoryCache, makeVar } from '@apollo/client';
import { Cart } from '../providers/Cart';
import { Promocode } from '../gql/graphql';
import { FormDataType } from '../pages/Order/Form/Form';

export const Cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        telegramInitDataVar: {
          read() {
            return telegramInitDataVar();
          },
        },
        cartVar: {
          read() {
            return cartVar();
          },
        },
        promocodeVar: {
          read() {
            return promocodeVar();
          },
        },
        formDataVar: {
          read() {
            return formDataVar();
          },
        },
      },
    },
  },
});

export const telegramInitDataVar = makeVar('');
export const cartVar = makeVar<Cart>({});
export const promocodeVar = makeVar<Promocode | null>(null);
export const formDataVar = makeVar<FormDataType | null>(null);
