import styled, { css } from 'styled-components';
import { getTransition } from '../../../styles/utilites';

export const Wrapper = styled.div``;
export const CustomInput = styled.input`
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  padding: 0.85em 1em;
  max-width: 100%;
  font-size: 1rem;
  border-radius: 1em;
  border: 1px solid transparent;
  outline: none;
  height: auto;
  &:focus-within {
    border: 1px solid var(--tg-theme-button-color);
    box-shadow: none;
  }
  ${getTransition(300, 'border-color')}
`;
export const FormSection = styled.div`
  margin-bottom: 0.75rem;
  input {
    width: 100%;
  }
  .react-dadata__suggestions {
    border-radius: 1rem;
    background: var(--tg-theme-bg-color);
  }
  .react-dadata__suggestion {
    color: var(--tg-theme-text-color) !important;
  }
`;
export const Title = styled.h6`
  font-weight: 600;
  line-height: 1.4;
`;
export const Required = styled.span`
  color: var(--tg-theme-destructive-text-color);
`;
export const Description = styled.div`
  font-size: 0.9rem;
  color: var(--tg-theme-hint-color);
`;
export const SevenPlus = styled.div`
  background: var(--tg-theme-bg-color);
  padding: 0.85em 1em;
  max-width: 100%;
  font-size: 1rem;
  border-radius: 1em;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 3px;
  border: 1px solid transparent;
  border-right: 0;
  outline: none;

  ${getTransition(300, 'border-color')}
`;
export const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .flag-dropdown {
    display: none;
  }
  input.form-control {
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    padding: 0.85em 1em;
    max-width: 100%;
    font-size: 1rem;
    border-radius: 1em;
    border: 1px solid transparent;
    outline: none;
    height: auto;
    width: 100%;
    line-height: 1.4;
  }
  input.form-control:focus-within {
    border: 1px solid var(--tg-theme-button-color);
  }
  &:focus-within ${SevenPlus} {
    border: 1px solid var(--tg-theme-button-color);
    border-right: 0;
  }
`;

export const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.75rem;
  > div:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

type ToggleWrapperProps = {
  $marginBottom?: number;
};

export const ToggleWrapper = styled.span<ToggleWrapperProps>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 0}rem;
`;

export const NoSelectDivider = styled.div`
  user-select: none;
`;

export const ToggleBtnWrapper = styled.div`
  position: relative;
  height: 20px;
  width: 36px;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
`;

const inactiveMixin = css`
  content: '';
  position: absolute;
  display: block;
`;
const afterAnimation = css`
  box-shadow:
    0 0 0 1px hsla(0, 0%, 0%, 0.1),
    0 4px 0px 0 hsla(0, 0%, 0%, 0.04),
    0 4px 9px hsla(0, 0%, 0%, 0.13),
    0 3px 3px hsla(0, 0%, 0%, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
`;
export const ToggleInput = styled.input`
  opacity: 0;
  position: absolute;
  & + label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    user-select: none;
    transition: 0.4s ease;
    height: 20px;
    width: 36px;
    border: 1px solid #e4e4e4;
    border-radius: 60px;
    background: hsl(0, 0%, 68%);
    &:after {
      ${inactiveMixin};
      ${afterAnimation};
      background: whitesmoke;
      height: 18px;
      width: 18px;
      top: 0px;
      left: 0px;
      border-radius: 50%;
    }
  }
  &:checked {
    & + label {
      background: var(--tg-theme-button-color);
    }
    & + label:after {
      left: 16px;
    }
  }
`;

export const DateTitleDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  > h6 {
    margin: 0;
  }
`;
