import * as S from './styles';

type HeaderProps = {
  heading: string;
  to: string;
  toText: string;
};
export const Header = ({ heading, toText, to }: HeaderProps) => {
  return (
    <S.Wrapper>
      <S.Heading>{heading}</S.Heading>
      <S.Button to={to}>{toText}</S.Button>
    </S.Wrapper>
  );
};
