import { useCallback, useMemo, useState } from 'react';
import { Messages, validation as getValidation } from '../functions/validation';
import { debounce } from 'lodash';

export const useValidation = (
  deliveryTime: number,
  delay?: number,
): [
  Messages | null,
  (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => React.ChangeEvent<HTMLInputElement>,
] => {
  const [validMessages, setValidMessages] = useState<Messages | null>(null);

  const validation = useMemo(
    () =>
      getValidation({
        optional: [],
        deliveryTime,
      }),
    [],
  );
  const checkValid = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const valid = validation({
        [e.target.name]: e.target.value,
      });
      setValidMessages({
        ...validMessages,
        ...valid,
      });
    },
    [validMessages],
  );
  const debouncedFn = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        checkValid(e);
      }, delay ?? 500),
    [],
  );

  const onType = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFn(e);

    return e;
  }, []);

  return [validMessages, onType];
};
