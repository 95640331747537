import { useCallback, useContext } from 'react';
import { Cart, CartContext, CartItem } from '../providers/Cart';
import { cartVar } from '../graphql/Cache';
import { useTgMainBtn } from './useTgMainBtn';
import { useTelegram } from './useTelegram';

export const useCart = () => {
  const { tg } = useTelegram();
  const { cart } = useContext(CartContext);
  const { setText, showButton, hideButton } = useTgMainBtn();

  const changeMainBtn = useCallback(
    (items: CartItem[]) => {
      if (items.length) {
        setText(
          `Корзина (${items
            .map((i) => i.count)
            .reduce((val, acc) => val + acc)}) на ${items
            .map((i) => i.price * i.count)
            .reduce((val, acc) => val + acc)}₽`,
        );
        showButton();
      } else {
        hideButton();
      }
    },
    [tg],
  );

  const makeChanges = useCallback(
    (restaurant: string, newItems: CartItem[], changeMain = true) => {
      const newCart: Cart = {
        ...cart,
        [restaurant]: newItems,
      };
      cartVar(newCart);
      localStorage.setItem('cart', JSON.stringify(newCart));
      if (changeMain) changeMainBtn(newItems);
    },
    [cart, changeMainBtn],
  );

  return { cart, changeMainBtn, makeChanges };
};
