import {
  BackButtonCallback,
  CloudStorageCallback,
  IBackButton,
  ICloudStorage,
  IHapticFeedback,
  IMainButton,
  IMainButtonParams,
  ImpactStyle,
  IPopupParams,
  IScanQrPopupParams,
  ISettingsButton,
  IWebApp,
  IWebAppUser,
  MainButtonCallback,
  NotificationType,
  SettingsButtonCallback,
} from '../types';

class MainButton implements IMainButton {
  isVisible = false;
  isActive = true;
  textColor = '#fff';
  isProgressVisible = false;
  color = 'blue';
  text = 'CONTINUE';

  hide() {
    console.log('hide MainButton');
    this.isVisible = false;
    return this;
  }
  show() {
    console.log('show MainButton');
    this.isVisible = true;
    return this;
  }
  disable() {
    console.log('disable MainButton');
    this.isActive = false;
    return this;
  }
  enable() {
    console.log('enable MainButton');
    this.isActive = true;
    return this;
  }
  setText(text: string) {
    console.log('set text to MainButton');
    return this;
  }
  onClick(callback: MainButtonCallback) {
    console.log('set text to MainButton');
    return this;
  }
  hideProgress() {
    console.log('hide progress MainButton');
    return this;
  }
  offClick(callback: MainButtonCallback) {
    console.log('offClick MainButton');
    return this;
  }
  setParams(params: IMainButtonParams) {
    console.log('setParams to MainButton');
    return this;
  }
  showProgress(leaveActive?: boolean) {
    console.log('show progress MainButton');
    return this;
  }
}

class BackButton implements IBackButton {
  isVisible = false;

  hide() {
    console.log('hide BackButton');
    this.isVisible = false;
    return this;
  }
  show() {
    console.log('show BackButton');
    this.isVisible = true;
    return this;
  }
  offClick(callback: BackButtonCallback) {
    console.log('offClick BackButton');
    return this;
  }
  onClick(callback: BackButtonCallback) {
    console.log('onClick BackButton');
    return this;
  }
}

class CloudStorage implements ICloudStorage {
  getItem(key: string, callback: CloudStorageCallback<string>): ICloudStorage {
    return this;
  }
  setItem(
    key: string,
    value: string,
    callback?: CloudStorageCallback<boolean>,
  ): ICloudStorage {
    return this;
  }
  getKeys(callback: CloudStorageCallback<string[]>): ICloudStorage {
    return this;
  }
  getItems(
    keys: string[],
    callback: CloudStorageCallback<{ [p: string]: string }>,
  ): ICloudStorage {
    return this;
  }
  removeItem(
    key: string,
    callback?: CloudStorageCallback<boolean>,
  ): ICloudStorage {
    return this;
  }
  removeItems(
    keys: string[],
    callback?: CloudStorageCallback<boolean[]>,
  ): ICloudStorage {
    return this;
  }
}

class HapticFeedback implements IHapticFeedback {
  impactOccurred(style: ImpactStyle): IHapticFeedback {
    return this;
  }
  notificationOccurred(type: NotificationType): IHapticFeedback {
    return this;
  }
  selectionChanged(): IHapticFeedback {
    return this;
  }
}

class SettingsButton implements ISettingsButton {
  isVisible = false;
  show(): ISettingsButton {
    console.log('show SettingsButton');
    return this;
  }
  hide(): ISettingsButton {
    console.log('hide SettingsButton');
    return this;
  }
  onClick(callback: SettingsButtonCallback): ISettingsButton {
    console.log('onClick SettingsButton');
    return this;
  }
  offClick(callback: SettingsButtonCallback): ISettingsButton {
    console.log('offClick SettingsButton');
    return this;
  }
}

const user: IWebAppUser = {
  id: 123,
  first_name: 'Sergey',
  language_code: 'ru',
  last_name: 'Ivanov',
  username: 'SergiyIva',
};
const initDataUnsafe = {
  user,
  hash: '#qwer12345',
  auth_date: 897450162345,
};
const themeParams = {
  bg_color: '#ffffff', // Background color: White
  text_color: '#000000', // Primary text color: Black
  hint_color: '#8e8e93', // Hint text color: Grey
  link_color: '#007aff', // Link text color: Blue
  button_color: '#007aff', // Button background color: Blue
  button_text_color: '#ffffff', // Button text color: White
  secondary_bg_color: '#f2f2f7', // Secondary background color: Light Grey
  header_bg_color: '#f7f7f7', // Header background color: Very Light Grey
  accent_text_color: '#007aff', // Accent text color: Blue (for important items)
  destructive_text_color: '#ff3b30', // Destructive action text color: Red (for warnings or delete actions)
  section_bg_color: '#f2f2f7', // Section background color: Light Grey (for grouping items)
  section_header_text_color: '#6d6d72', // Section header text color: Dark Grey
  subtitle_text_color: '#8e8e93', // Subtitle text color: Grey (for less important text)
};

class WebApp implements IWebApp {
  backgroundColor = '#fff';
  headerColor = '#fff';
  colorScheme = 'light' as 'light';
  isExpanded = true;
  isClosingConfirmationEnabled = false;
  initData =
    process.env.NODE_ENV === 'production'
      ? ''
      : 'query_id=AAGswmd1AgAAAKzCZ3WBeBlp&user=%7B%22id%22%3A6264701612%2C%22first_name%22%3A%22Sergey%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SergiyIva%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1708248998&hash=5b35c2bc5dd423f59d7ed93d913dce7f3f75ac5f55c73cce2c3f3c3bdf0f9f97';
  platform = 'Linux';
  version = '7.1';
  initDataUnsafe = initDataUnsafe;
  themeParams = themeParams;
  viewportHeight = 0;
  viewportStableHeight = 0;

  MainButton = new MainButton();
  BackButton = new BackButton();
  CloudStorage = new CloudStorage();
  HapticFeedback = new HapticFeedback();
  SettingsButton = new SettingsButton();

  // Methods
  isVersionAtLeast(version: string): boolean {
    console.log('isVersionAtLeast');
    return true;
  }
  setHeaderColor(color: string): void {
    console.log('setHeaderColor');
    this.headerColor = color;
  }
  setBackgroundColor(color: string): void {
    console.log('setBackgroundColor');
    this.backgroundColor = color;
  }
  enableClosingConfirmation(): void {
    console.log('enableClosingConfirmation');
    this.isClosingConfirmationEnabled = true;
  }
  disableClosingConfirmation(): void {
    console.log('disableClosingConfirmation');
    this.isClosingConfirmationEnabled = false;
  }
  onEvent(eventType: string, eventHandler: (...args: any[]) => void): void {
    console.log('onEvent');
  }
  offEvent(eventType: string, eventHandler: (...args: any[]) => void): void {
    console.log('offEvent');
  }
  sendData(data: string): void {
    console.log('sendData');
  }
  switchInlineQuery(query: string, choose_chat_types?: string[]): void {
    console.log('switchInlineQuery');
  }
  openLink(url: string, options?: { try_instant_view?: boolean }): void {
    console.log('openLink');
  }
  openTelegramLink(url: string): void {
    console.log('openTelegramLink');
  }
  openInvoice(url: string, callback?: (status: any) => void): void {
    console.log('openInvoice');
  }
  showPopup(params: IPopupParams, callback?: (id: string) => void): void {
    console.log('showPopup');
  }
  showAlert(message: string, callback?: () => void): void {
    console.log('showAlert');
  }
  showConfirm(message: string, callback?: (ok: boolean) => void): void {
    console.log('showConfirm');
  }
  showScanQrPopup(
    params: IScanQrPopupParams,
    callback?: (text: string) => boolean,
  ): void {
    console.log('showScanQrPopup');
  }
  closeScanQrPopup(): void {
    console.log('closeScanQrPopup');
  }
  readTextFromClipboard(callback?: (text: string) => void): void {
    console.log('readTextFromClipboard');
  }
  requestWriteAccess(callback?: (granted: boolean) => void): void {
    console.log('requestWriteAccess');
  }
  requestContact(callback?: (shared: boolean) => void): void {
    console.log('requestContact');
  }
  ready(): void {
    console.log('ready');
  }
  expand(): void {
    console.log('expand');
    this.isExpanded = true;
  }
  close(): void {
    console.log('close');
  }
}

export const tgMock: IWebApp = new WebApp();
