import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { CHECK_PROMOCODE } from '../../../graphql/Query';
import * as S from './styles';
import { useState } from 'react';
import { promocodeVar } from '../../../graphql/Cache';
import { Promocode } from '../../../gql/graphql';
import { useFormInput } from '../../../hooks/useFormInput';
import {
  ErrorNotification,
  Notification,
  Relative,
} from '../../../styles/components';
import { FormSection, Title } from '../../Order/Form/styles';

type PromocodeComponentProps = {};
export const PromocodeComponent = ({}: PromocodeComponentProps) => {
  const acceptedPromo = useReactiveVar(promocodeVar);
  const [values] = useFormInput({
    promocode: acceptedPromo?.code ?? '',
  });
  const [error, setError] = useState('');
  const [check, { loading, data }] = useLazyQuery(CHECK_PROMOCODE, {
    onError: (err) => {
      console.log(err);
      if (err.message === 'No promocode') setError('Недействительный промокод');
      else if (err.message === 'No repeat promocode')
        setError('Вы уже применяли данный промокод');
      else setError('Ошибка при попытке применения промокода');
    },
    onCompleted: (data) => {
      if (data && data.checkPromocode) {
        promocodeVar(data.checkPromocode as Promocode);
        setError('');
      }
    },
  });

  const onClick = async () => {
    promocodeVar(null);
    await check({
      variables: {
        promocodeInput: {
          code: values.value.promocode,
        },
      },
    });
  };

  return (
    <FormSection>
      <Title>Промокод</Title>
      <Relative>
        <S.PromocodeWrapper>
          <input
            id={'promocode'}
            name={'promocode'}
            value={values.value.promocode}
            onChange={values.onChange}
          />
          <S.Accept onClick={onClick} disabled={loading}>
            {loading ? 'Загрузка' : 'Применить'}
          </S.Accept>
        </S.PromocodeWrapper>
        <Notification $show={!!acceptedPromo}>
          <div>
            Промокод применён (при заказе от {acceptedPromo?.minSum} руб.)
          </div>
        </Notification>
        <ErrorNotification $show={!!error}>
          <div>{error}</div>
        </ErrorNotification>
      </Relative>
    </FormSection>
  );
};
