import * as S from './styles';
import { ReactElement } from 'react';

type DropdownProps = {
  isOpen: boolean;
  array: { content: string | ReactElement; action: () => void }[];
  widthInRem?: number;
  direction?: 'top' | 'bottom';
};
export const Dropdown = ({
  isOpen,
  array,
  widthInRem,
  direction = 'top',
}: DropdownProps) => {
  return (
    <S.Wrapper
      $isOpen={isOpen}
      $direction={direction}
      $widthInRem={widthInRem}
      $isJsxIn={typeof array[0].content !== 'string'}
      data-testid={'dropdown'}
    >
      <S.Container>
        {array.map((elt, i) => (
          <li
            key={i}
            onClick={elt.action}
            data-testid={'dropdown-elt'}
            // isJsxIn={typeof elt.title !== "string"}
          >
            <div>{elt.content}</div>
          </li>
        ))}
      </S.Container>
    </S.Wrapper>
  );
};
