import { IWebApp, IWebAppInitData, IWebAppUser } from '../types';
import { createContext, useLayoutEffect, useMemo, useState } from 'react';
import { tgMock } from '../mock/tg';

export interface ITelegramContext {
  tg?: IWebApp;
  user?: IWebAppUser;
  unsafeData?: IWebAppInitData;
}

const mockData = {
  tg: tgMock,
  unsafeData: tgMock.initDataUnsafe,
  user: tgMock.initDataUnsafe.user,
};
const data = process.env.NODE_ENV === 'production' ? {} : mockData;

export const TelegramContext = createContext<ITelegramContext>(data);
export const TelegramProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tg, setTg] = useState<IWebApp | null>(null);

  useLayoutEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    if (app) {
      app.ready();
      setTg(app);
    }
  }, []);

  const value: ITelegramContext = useMemo(() => {
    return tg
      ? {
          tg,
          unsafeData: tg.initDataUnsafe,
          user: tg.initDataUnsafe.user,
        }
      : data;
  }, [tg]);

  return (
    <TelegramContext.Provider value={value}>
      {children}
    </TelegramContext.Provider>
  );
};
