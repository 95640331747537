import * as S from '../styles';
import { useQuery } from '@apollo/client';
import { GET_DISTRICTS } from '../../../../graphql/Query';

type TotalProps = {
  total: number;
  discount: number;
  isSelf: boolean;
  district: string;
};
export const Total = ({ total, discount, isSelf, district }: TotalProps) => {
  const { data } = useQuery(GET_DISTRICTS);
  const current = data?.districts?.find((dist) => dist!.id === district);

  const totalWithDelivery =
    !isSelf && current && total < current.freeDeliveryFrom
      ? total + current.deliveryPrice
      : total;

  return (
    <div>
      <S.Title>Итого с доставкой: {totalWithDelivery.toFixed(0)} руб.</S.Title>
      {isSelf && <S.Description>Доставка: бесплатно</S.Description>}
      {!isSelf && current && (
        <S.Description>
          Доставка:{' '}
          {total < current.freeDeliveryFrom
            ? `${current.deliveryPrice} руб.`
            : `бесплатно`}
        </S.Description>
      )}
      <S.Description>Скидка: {Math.ceil(discount)} руб.</S.Description>
    </div>
  );
};
