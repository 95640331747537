import { useReloadScrollTop } from '../../hooks/useReloadScrollTop';
import { Section } from '../../styles/components';
import { useTgBack } from '../../hooks/useTgBack';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ME } from '../../graphql/Query';
import { useTelegram } from '../../hooks/useTelegram';
import { Header } from '../Cart/Header/Header';
import { Form } from './Form/Form';

export const OrderPage = () => {
  const {
    data: me,
    refetch,
    loading,
  } = useQuery(GET_ME, {
    onError: (err) => console.log(err),
  });
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const onBack = useCallback(() => {
    navigate('/cart');
  }, []);

  useReloadScrollTop();

  useTgBack(onBack);

  useEffect(() => {
    refetch();
  }, [tg]);

  useEffect(() => {
    if (
      !!tg &&
      !loading &&
      process.env.NODE_ENV === 'production' &&
      (!me || !me.getMe)
    ) {
      navigate('/cart');
    }
  }, [tg, me]);

  return (
    <Section style={{ minHeight: '100vh' }}>
      <Header heading={'Оформление'} toText={'Назад в корзину'} to={'/cart'} />
      <Form
        phone={me?.getMe?.phone ?? ''}
        address={me?.getMe?.address ?? ''}
        district={me?.getMe?.district ?? ''}
      />
    </Section>
  );
};
