import { GetCategoriesQuery } from '../../../gql/graphql';
import * as S from './styles';
import { URI } from '../../../index';

type CategoryProps = {
  category: Exclude<GetCategoriesQuery['categories'][0], null>;
};

export const Category = ({ category }: CategoryProps) => {
  return (
    <S.StyledLink href={`#${category.type}`}>
      <S.Image
        src={URI + category.img}
        height={68}
        width={68}
        alt={category.name}
      />
      <S.Text>{category.name.replace(/(\S+\s+)/, '')}</S.Text>
    </S.StyledLink>
  );
};
