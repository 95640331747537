import * as S from '../styles';
import { URI } from '../../../index';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from '@apollo/client';
import { GET_ME } from '../../../graphql/Query';
import { useEffect } from 'react';
import { useTelegram } from '../../../hooks/useTelegram';

type HeaderProps = {};
export const Header = ({}: HeaderProps) => {
  const { user, tg } = useTelegram();
  const {
    data: me,
    loading: loadMe,
    refetch,
  } = useQuery(GET_ME, {
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    refetch();
  }, [tg]);

  const onShare = () => {
    tg?.requestContact((isShared) => {
      if (isShared) refetch();
    });
  };

  return (
    <S.Header>
      <S.Row>
        <S.Logo src={URI + 'logo.png'} />
        <S.TitleWrapper>
          <S.Title>ОмНомНом</S.Title>
          <S.SubTitle>Паназиатская кухня</S.SubTitle>
        </S.TitleWrapper>
      </S.Row>
      {((me && me.getMe) || (user && user.first_name)) && (
        <S.RightDivider>
          <S.Name className={'username'}>
            {user && user.first_name ? user.first_name : me!.getMe!.name}
          </S.Name>
          {loadMe ? (
            <Skeleton
              baseColor={tg?.backgroundColor}
              highlightColor={tg?.themeParams.section_bg_color}
              width={100}
              height={18}
            />
          ) : me && me.getMe ? (
            <S.Confirmed>Подтвержден</S.Confirmed>
          ) : (
            <S.SharedBtn onClick={onShare}>Подтвердить</S.SharedBtn>
          )}
        </S.RightDivider>
      )}
    </S.Header>
  );
};
