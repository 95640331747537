import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { Popover } from '../../../components/Popover/Popover';
import { useEffect } from 'react';
import ClipboardJS from 'clipboard';
import { WEB_APP, WEB_APP_NAME } from '../../../constants/constants';

type FixedControlProps = {
  id: string;
};
export const FixedControl = ({ id }: FixedControlProps) => {
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/');
  };

  useEffect(() => {
    new ClipboardJS('#copy-link-product', {});
  }, []);

  return (
    <S.Wrapper>
      <S.ControlButton onClick={onClose}>
        <span className={'icon-cross'} />
      </S.ControlButton>
      <Popover
        title={'Ссылка успешно скопирована'}
        direction={'left'}
        widthInRem={16}
      >
        <S.ControlButton
          // onClick={onCopy}
          id={'copy-link-product'}
          data-clipboard-text={`${WEB_APP}/${WEB_APP_NAME}?startapp=product_${id}`}
        >
          <span className={'icon-link'} />
        </S.ControlButton>
      </Popover>
    </S.Wrapper>
  );
};
