import map from '../../../assets/imgs/map.webp';
import styled from 'styled-components';

export const pays = [
  {
    type: 'bankCard',
    title: 'Оплата банковской картой',
    description: 'Онлайн оплата любой картой Visa, MasterCard, Мир',
  },
  // {
  //   type: 'sbp',
  //   title: 'Перевод по реквизитам',
  //   description:
  //     'После оформления заказа вы получите номер карты, на которую перевести средства',
  // },
  // {
  //   type: 'cash',
  //   title: 'Наличными при получении',
  //   description: 'Рассчитаться можно с курьером наличными при получении товара',
  // },
  // {
  //   type: 'crypto',
  //   title: 'Оплата криптовалютой USDT',
  //   description: 'Оплата через криптокошелек',
  // },
];

const Sale = styled.span`
  font-size: 0.85rem;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.green};
  background-color: var(--tg-theme-bg-color);
  padding: 0.25em 0.75em;
  border-radius: 10px;
`;

export const deliveries = [
  {
    type: 'courier',
    title: 'Доставка курьером',
    description: 'Наш курьер доставит заказ прямо до двери',
    img: map,
  },
  {
    type: 'self',
    title: (
      <div>
        Самовывоз
        {/*<Sale>Скидка 15%</Sale>*/}
      </div>
    ),
    description: 'Заказ вы можете получить в выбранном ресторане',
  },
];
