import styled from 'styled-components';

export const PromocodeWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Accept = styled.button`
  position: absolute;
  right: 16px;
  color: var(--tg-theme-button-color);
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  background: transparent;
  border: none;
  padding: 0;
`;
