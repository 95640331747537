import { createContext, PropsWithChildren, useLayoutEffect } from 'react';
import { cartVar } from '../graphql/Cache';
import { useReactiveVar } from '@apollo/client';

export type CartItem = {
  id: string;
  price: number;
  count: number;
};

export type Cart = {
  [key: string]: CartItem[];
};

interface ICartContext {
  cart: Cart;
}
export const CartContext = createContext<ICartContext>({
  cart: {},
});

export const CartProvider = ({ children }: PropsWithChildren<{}>) => {
  const cart = useReactiveVar(cartVar);

  useLayoutEffect(() => {
    cartVar(
      !!localStorage.getItem('cart')
        ? (JSON.parse(localStorage.getItem('cart') as string) as Cart)
        : {},
    );
  }, []);

  const value = { cart };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
