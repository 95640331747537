import * as S from '../../pages/Order/Form/styles';

type TogglerProps = {
  title: string;
  isChecked: boolean;
  onChange: () => void;
  isRevert?: boolean;
  mb?: number;
};
type BtnProps = {
  isChecked: boolean;
  onChange: () => void;
};
type TitleProps = {
  title: string;
};

const Title = ({ title }: TitleProps) => (
  <S.NoSelectDivider>{title}</S.NoSelectDivider>
);
const Btn = ({ isChecked, onChange }: BtnProps) => (
  <S.ToggleBtnWrapper>
    <S.ToggleInput
      type="checkbox"
      name="toggle1"
      id="toggle1"
      checked={isChecked}
      onChange={onChange}
    />
    <label htmlFor="toggle1"></label>
  </S.ToggleBtnWrapper>
);

export const Toggler = ({
  title,
  isChecked,
  onChange,
  isRevert,
  mb,
}: TogglerProps) => {
  return (
    <S.ToggleWrapper $marginBottom={mb}>
      {isRevert ? (
        <>
          <Title title={title} />
          <Btn isChecked={isChecked} onChange={onChange} />
        </>
      ) : (
        <>
          <Btn isChecked={isChecked} onChange={onChange} />
          <Title title={title} />
        </>
      )}
    </S.ToggleWrapper>
  );
};
