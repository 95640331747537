import * as S from '../styles';
import { getLocalCountValue } from '../../../functions/getLocalCountValue';
import { useReactiveVar } from '@apollo/client';
import { promocodeVar } from '../../../graphql/Cache';
import { MIN_ORDER_SUM } from '../Cart';

type TotalProps = {
  itemsCount: number;
  totalPrice: number;
  isSelf?: boolean;
  info?: string;
};
export const Total = ({ itemsCount, totalPrice, isSelf, info }: TotalProps) => {
  const promocode = useReactiveVar(promocodeVar);

  const discount = !!promocode?.discount
    ? Math.min(promocode.maxDiscount!, totalPrice * (promocode.discount / 100))
    : 0;
  const total = totalPrice - discount;

  return (
    <S.TotalWrapper>
      <S.CountItems>
        В корзине{' '}
        {getLocalCountValue(itemsCount, 'позиция', 'позиции', 'позиций')}
      </S.CountItems>
      {!isSelf && totalPrice < MIN_ORDER_SUM && (
        <div>
          <S.RedText>Минимальная сумма заказа 2000 рублей!</S.RedText>
        </div>
      )}
      <S.Total>
        Итого: {!!discount && <s>{totalPrice}</s>}{' '}
        <S.RedText>{total.toFixed(2)} руб.</S.RedText>
      </S.Total>
      <S.CountItems>Скидка: {discount.toFixed(2)} руб.</S.CountItems>
      {!!info && <S.SpecialInfo>{info}</S.SpecialInfo>}
    </S.TotalWrapper>
  );
};
