import { ProductFlatten } from '../ProductGroup';
import * as S from './styles';
import { Link } from 'react-router-dom';
import { ProductImage } from '../../../../../../components/ProductImage/ProductImage';
import { ProductPriceAndWeight } from '../../../../../../components/ProductPriceAndWeight/ProductPriceAndWeight';
import { CartButton } from '../../../../../../components/CartButton/CartButton';

type ProductCardProps = {
  product: ProductFlatten;
  isNoApp: boolean;
};
export const ProductCard = ({ product, isNoApp }: ProductCardProps) => {
  return (
    <S.Wrapper>
      <Link to={'product/' + product.id}>
        <ProductImage src={product.img} alt={product.name} />
      </Link>
      <S.Name>{product.name}</S.Name>
      <ProductPriceAndWeight price={product.price} weight={product.weight} />
      <CartButton
        isNoApp={isNoApp}
        btnText={'В корзину'}
        product={product}
        isCard={true}
      />
    </S.Wrapper>
  );
};
