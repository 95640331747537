import { Product } from '../../../gql/graphql';
import { ProductImage } from '../../../components/ProductImage/ProductImage';
import { CartButton } from '../../../components/CartButton/CartButton';
import * as S from './styles';
import { useCart } from '../../../hooks/useCart';
import { RedText } from '../styles';

type CartPositionProps = {
  product: Product;
  count: number;
  isBonus?: boolean;
};
export const CartPosition = ({
  product,
  count,
  isBonus,
}: CartPositionProps) => {
  const { cart, makeChanges } = useCart();
  const restaurant = !!localStorage.getItem('restaurantId')
    ? (localStorage.getItem('restaurantId') as string)
    : '1';
  //isBonus
  const price = Math.floor(product.price * count);

  const onDelete = () => {
    if (isBonus) return;
    const newItems = cart[restaurant].filter((i) => i.id !== product.id);
    makeChanges(restaurant, newItems, false);
  };

  return (
    <S.Wrapper>
      <S.PositionContentWrapper>
        <ProductImage src={product.img} alt={product.name} />

        <S.PositionContent>
          <S.Price>
            {isBonus ? (
              <>
                <s>{price}</s> <RedText>0 руб.</RedText>
              </>
            ) : (
              <>{price} руб.</>
            )}
          </S.Price>
          <S.Name>{product.name}</S.Name>
          {isBonus ? (
            <div>
              <i>1 позиция в подарок</i>
            </div>
          ) : (
            <CartButton
              product={product}
              isCard={true}
              isCart={true}
              btnText={''}
              isNoApp={false}
            />
          )}
        </S.PositionContent>
      </S.PositionContentWrapper>
      {!isBonus && <S.Cross className={'icon-cross'} onClick={onDelete} />}
    </S.Wrapper>
  );
};
