import styled from 'styled-components';
import { getTransition } from '../../../styles/utilites';

export const Wrapper = styled.div`
  background-color: var(--tg-theme-bg-color);
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 1em;
  padding: 0.25em 0.75em;
  margin-bottom: 0.75rem;
  color: var(--tg-theme-text-color);
  //box-shadow: 0 2px 5px 1px hsla(0, 0%, 10%, 0.25);
  box-shadow: 0 2px 3px 1px
    color-mix(in srgb, var(--tg-theme-text-color), transparent 80%);
`;

export const PositionContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  img {
    width: 70px;
    height: auto;
    border-radius: 16px;
    margin: 0 0.5em;
  }
`;

export const PositionContent = styled.div`
  text-align: left;
  margin-left: 0.75em;
  flex-grow: 1;
  padding: 0.5rem 0;
`;

export const Price = styled.div`
  font-weight: bold;
`;
export const Name = styled.div`
  font-weight: bold;
  font-size: 0.85rem;
  margin-bottom: 1em;
`;

export const Cross = styled.div`
  cursor: pointer;
  padding: 0.5em;
  font-weight: 300;
  color: var(--tg-theme-hint-color);
  &:hover {
    color: var(--tg-theme-destructive-text-color);
  }
  ${getTransition()}
`;
