import { useQuery } from '@apollo/client';
import { GET_PRODUCTS } from '../../../graphql/Query';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import { Product } from '../../../gql/graphql';
import { Products } from './Products/Products';
import { useTelegram } from '../../../hooks/useTelegram';
import { WEB_APP } from '../../../constants/constants';

type PositionsProps = {
  restaurant: string;
  isNoApp: boolean;
};
export const Positions = ({ restaurant, isNoApp }: PositionsProps) => {
  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: {
      restaurantId: restaurant,
    },
  });
  const { tg } = useTelegram();

  return (
    <S.ListContainer>
      {loading ? (
        <S.SkeletonContainer>
          <Skeleton
            baseColor={tg?.backgroundColor}
            highlightColor={tg?.themeParams.section_bg_color}
            height={30}
            width={'30%'}
            borderRadius={8}
            style={{ margin: '2rem 0 0.75rem' }}
          />
          <Skeleton
            baseColor={tg?.backgroundColor}
            highlightColor={tg?.themeParams.section_bg_color}
            height={220}
            width={'40%'}
            borderRadius={8}
            count={8}
            //  wrapper={SkeletonWrapper}
            //   inline
          />
        </S.SkeletonContainer>
      ) : data && data.products.length ? (
        <Products products={data.products as Product[]} isNoApp={isNoApp} />
      ) : isNoApp ? (
        <S.NoAppDivider>
          <S.NoAppText>
            Чтобы выполнить заказ лучшей паназиатской кухни, переходи в нашего
            телеграм бота!
          </S.NoAppText>
          <S.NoAppLink href={WEB_APP}>Telegram OmNomNom App</S.NoAppLink>
        </S.NoAppDivider>
      ) : (
        <div style={{ textAlign: 'center' }}>Позиции меню отсутствуют</div>
      )}
    </S.ListContainer>
  );
};
