import styled, { css } from 'styled-components';

type ButtonProps = {
  $isCard: boolean;
  $isCart?: boolean;
};

export const NoAppLink = styled.a<ButtonProps>`
  background: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
  border-radius: 10px;
  padding: ${({ $isCard }) => ($isCard ? '0.65rem 1.5rem' : '0.8rem 3rem')};
  text-decoration: none;
`;

export const InCartDivider = styled.div<Partial<ButtonProps>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--tg-theme-secondary-bg-color);
  border-radius: 10px;
  line-height: normal;
  ${({ $isCart }) =>
    $isCart &&
    css`
      max-width: 120px;
    `}
`;

export const Count = styled.div`
  font-weight: 700;
  font-size: 1.15rem;
`;

export const CartButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--tg-theme-text-color);
  ${({ $isCart }) =>
    $isCart &&
    css`
      font-size: 12px;
    `}
`;

export const Minus = styled(CartButton)`
  padding: ${({ $isCard }) =>
    $isCard ? '0.85rem 1rem' : '1rem 2rem 1rem 4rem'};
`;

export const Plus = styled(CartButton)`
  padding: ${({ $isCard }) =>
    $isCard ? '0.85rem 1rem' : '1rem 4rem 1rem 2rem'};
`;

export const Button = styled.button<ButtonProps>`
  ${({ $isCard }) =>
    !$isCard &&
    css`
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    `}
`;
