import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/style.css';
import './styles/index.css';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { baseTheme } from './styles/theme';
import { ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client';
import { Cache, telegramInitDataVar } from './graphql/Cache';
import { setContext } from '@apollo/client/link/context';
import { TelegramProvider } from './providers/Telegram';
import { CartProvider } from './providers/Cart';

export const URI =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_URI_PROD
    : process.env.REACT_APP_URI;
const uri = `${URI}graphql/`;

const httpLink = createHttpLink({ uri, credentials: 'include' });

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: telegramInitDataVar() || '',
    },
  };
});

const httpWithHeaders = authLink.concat(httpLink);

const client = new ApolloClient({
  link: httpWithHeaders,
  cache: Cache,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <StyleSheetManager enableVendorPrefixes={true}>
      <ThemeProvider theme={baseTheme}>
        <ErrorBoundary>
          <TelegramProvider>
            <ApolloProvider client={client}>
              <CartProvider>
                <Router>
                  <App />
                </Router>
              </CartProvider>
            </ApolloProvider>
          </TelegramProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyleSheetManager>
  </React.StrictMode>,
);
