import { useTelegram } from './useTelegram';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useTgBack = (callback?: () => void) => {
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const goToMenu = () => navigate('/');

  useEffect(() => {
    if (!tg?.BackButton) return;
    tg.BackButton.show();
    tg.BackButton.onClick(callback ?? goToMenu);
    return () => {
      // tg.BackButton.hide();
      tg.BackButton.offClick(callback ?? goToMenu);
    };
  }, [tg?.BackButton, callback]);
};
