import { FC, memo } from 'react';
import * as S from './styles';
import { ScreenReader } from '../../../styles/utilites';

type TopSiteLinkProps = {
  isTopLinkVisible: boolean;
  scrollToTop: () => void;
};
const TopSiteLink: FC<TopSiteLinkProps> = ({
  isTopLinkVisible,
  scrollToTop,
}) => {
  return (
    <S.Container onClick={scrollToTop} $isVisible={isTopLinkVisible}>
      <ScreenReader>На верх страницы</ScreenReader>
      <span className="icon-arrow-up2" />
    </S.Container>
  );
};

export const PureTopSiteLink = memo(
  TopSiteLink,
  (prevProps, nextProps) =>
    prevProps.isTopLinkVisible === nextProps.isTopLinkVisible,
);
