import { useNavigate, useParams } from 'react-router-dom';
import { useTgBack } from '../../hooks/useTgBack';
import { Section } from '../../styles/components';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT } from '../../graphql/Query';
import * as S from './styles';
import { ProductImage } from '../../components/ProductImage/ProductImage';
import { ProductPriceAndWeight } from '../../components/ProductPriceAndWeight/ProductPriceAndWeight';
import { FixedButton } from './FixedButton/FixedButton';
import { FixedControl } from './FixedControl/FixedControl';
import Skeleton from 'react-loading-skeleton';
import { useTelegram } from '../../hooks/useTelegram';
import { useReloadScrollTop } from '../../hooks/useReloadScrollTop';
import { Product } from '../../gql/graphql';
import { useCallback, useEffect } from 'react';

const list: {
  type: 'protein' | 'fat' | 'carbohydrates' | 'calorific';
  name: string;
}[] = [
  {
    type: 'protein',
    name: 'Белки',
  },
  {
    type: 'fat',
    name: 'Жиры',
  },
  {
    type: 'carbohydrates',
    name: 'Углеводы',
  },
  {
    type: 'calorific',
    name: 'Калорийность',
  },
];

export const Position = () => {
  const { tg } = useTelegram();
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id as string;
  const { data, loading } = useQuery(GET_PRODUCT, {
    variables: {
      id,
    },
  });

  const goToCart = useCallback(() => navigate('/cart'), []);

  useEffect(() => {
    if (!tg) return;
    tg.MainButton.onClick(goToCart);
    return () => {
      tg.MainButton.offClick(goToCart);
    };
  }, [tg]);

  useTgBack();
  useReloadScrollTop();

  if ((!data || !data.product) && !loading)
    return (
      <Section style={{ textAlign: 'center' }}>
        <S.NotFound>Данная позиция не найдена.</S.NotFound>
        <button onClick={() => navigate('/')}>Вернуться в меню</button>
      </Section>
    );
  return (
    <>
      <Section style={{ minHeight: '100vh' }}>
        <S.ImageWrapper>
          {loading ? (
            <Skeleton
              highlightColor={tg?.themeParams.section_bg_color}
              baseColor={tg?.backgroundColor}
              height={'31vh'}
              width={'100%'}
            />
          ) : (
            <ProductImage src={data!.product!.img} alt={data!.product!.name} />
          )}
        </S.ImageWrapper>
        {loading ? (
          <S.ContentSkeletonWrapper>
            <Skeleton
              highlightColor={tg?.themeParams.section_bg_color}
              baseColor={tg?.backgroundColor}
              height={'42vh'}
              width={'100%'}
            />
          </S.ContentSkeletonWrapper>
        ) : (
          <S.ContentWrapper>
            <S.Name>{data!.product!.name}</S.Name>
            <S.Description>{data!.product!.description}</S.Description>
            <S.Title>Энергетическая ценность</S.Title>
            <S.List>
              {list.map(({ type, name }) => (
                <li key={type}>
                  <S.Property>
                    <div>{name}</div>
                  </S.Property>
                  <S.PropertyValue>{data!.product![type]}</S.PropertyValue>
                </li>
              ))}
            </S.List>
            <ProductPriceAndWeight
              price={data!.product!.price}
              weight={data!.product!.weight}
              withSpaces={true}
            />
          </S.ContentWrapper>
        )}
      </Section>
      {data && data.product && <FixedControl id={data.product.id} />}
      <FixedButton
        product={data?.product as Product | undefined}
        loading={loading}
      />
    </>
  );
};
