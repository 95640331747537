import styled from 'styled-components';

export const StyledLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  div {
    color: var(--tg-theme-text-color);
  }
`;
export const Image = styled.img`
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  width: 68px;
  height: 68px;
`;

export const Text = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
`;
