import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;
  text-align: left;
  display: flex;
  margin-bottom: 0.5rem;
`;
export const RadioBox = styled.div`
  border: 2px solid var(--tg-theme-button-color);
  background: transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  margin-right: 0.75rem;
  flex-shrink: 0;
`;

export const RadioCircle = styled.div`
  background: var(--tg-theme-button-color);
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

export const Description = styled.div`
  color: var(--tg-theme-hint-color);
  font-size: 0.85rem;
`;

export const Img = styled.img`
  width: auto;
  object-fit: cover;
  max-width: min(70vw, 500px);
  margin-top: 0.5em;
  border-radius: 1em;
`;
