import * as S from './styles';

type ProductPriceAndWeightProps = {
  price: number;
  weight: number;
  withSpaces?: boolean;
};
export const ProductPriceAndWeight = ({
  weight,
  price,
  withSpaces,
}: ProductPriceAndWeightProps) => {
  return (
    <S.Flex>
      <S.Price $withSpaces={withSpaces}>
        {price}
        {withSpaces ? ' ' : ''}₽
      </S.Price>
      {weight > 0 && (
        <S.Text>
          {weight}
          {withSpaces ? ' ' : ''}гр.
        </S.Text>
      )}
    </S.Flex>
  );
};
