import GlobalStyles from './styles/global';
import { Pages } from './pages';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTgInit } from './hooks/useTgInit';
import { useTelegram } from './hooks/useTelegram';
import { Cart } from './providers/Cart';
import { useCart } from './hooks/useCart';

const App = () => {
  const { tg } = useTelegram();
  const { changeMainBtn } = useCart();
  const navigate = useNavigate();

  useTgInit();

  useEffect(() => {
    const startParam = tg?.initDataUnsafe?.start_param;
    if (startParam) {
      const [path, id] = startParam.split('_');
      navigate(`/${path}/${id}`);
    }
  }, [tg]);

  useEffect(() => {
    const restaurant = !!localStorage.getItem('restaurantId')
      ? (localStorage.getItem('restaurantId') as string)
      : '1';
    const cart = !!localStorage.getItem('cart')
      ? (JSON.parse(localStorage.getItem('cart') as string) as Cart)
      : {};
    console.log(cart[restaurant]);
    if (cart[restaurant]) changeMainBtn(cart[restaurant]);
  }, [tg]);

  return (
    <>
      <GlobalStyles />
      <main>
        <Pages />
      </main>
    </>
  );
};

export default App;
