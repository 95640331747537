import * as S from './styles';
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_RESTAURANTS } from '../../../graphql/Query';
import { useCart } from '../../../hooks/useCart';
import { Selection } from '../../../components/Selection/Selection';

type RestSelectProps = {
  restaurant: string;
  setRestaurant: (id: string) => void;
};
export const RestSelect = ({ restaurant, setRestaurant }: RestSelectProps) => {
  const { cart, changeMainBtn } = useCart();
  const { data, loading } = useQuery(GET_RESTAURANTS, {
    onError: (error) => console.log(error),
    onCompleted: (data) => {
      if (
        data &&
        data.restaurants &&
        data.restaurants.length &&
        restaurant === '0'
      )
        setRestaurant(data.restaurants[0]!.id);
    },
  });

  useEffect(() => {
    changeMainBtn(cart[restaurant] ?? []);
  }, [restaurant]);

  return (
    <S.Wrapper>
      <S.Restaurant>Ресторан:</S.Restaurant>
      {loading || !data || !data.restaurants.length ? (
        '...'
      ) : (
        <Selection
          items={data.restaurants.map((rest) => ({
            content: rest!.name,
            id: rest!.id,
          }))}
          currentId={restaurant}
          setValue={setRestaurant}
        />
      )}
    </S.Wrapper>
  );
};
