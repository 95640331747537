import styled from 'styled-components';

export const CountItems = styled.div`
  color: var(--tg-theme-hint-color);
  margin-bottom: 0.75em;
`;

export const TotalWrapper = styled.div`
  background-color: var(--tg-theme-bg-color);
  width: 100%;
  border-radius: 1em;
  padding: 0.75em;
  margin-bottom: 0.75rem;
  box-shadow: 0 2px 3px 1px
    color-mix(in srgb, var(--tg-theme-text-color), transparent 80%);
`;

export const Total = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
`;
export const RedText = styled.span`
  color: var(--tg-theme-destructive-text-color);
  margin-bottom: 0.75em;
`;
export const SpecialInfo = styled(CountItems)`
  font-style: italic;
`;
