import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from '../../graphql/Query';
import { Category } from './Category/Category';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { Section } from '../../styles/components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Positions } from './Positions/Positions';
import { useTelegram } from '../../hooks/useTelegram';
import TopSiteLinkContainer from './TopSiteLink/TopSiteLinkContainer';
import { RestSelect } from './RestSelect/RestSelect';
import { useReloadScrollTop } from '../../hooks/useReloadScrollTop';
import { Header } from './Header/Header';
import { Link, useNavigate } from 'react-router-dom';

export const Main = () => {
  const [restaurant, setRestaurant] = useState(
    !!localStorage.getItem('restaurantId')
      ? (localStorage.getItem('restaurantId') as string)
      : '0',
  );
  const { data, loading } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-first',
    variables: {
      restaurantId: restaurant,
    },
  });
  const { user, tg } = useTelegram();
  const navigate = useNavigate();
  const goToCart = useCallback(() => navigate('/cart'), []);

  useEffect(() => {
    if (!tg) return;
    tg.MainButton.onClick(goToCart);
    return () => {
      tg.MainButton.offClick(goToCart);
    };
  }, [tg]);

  useEffect(() => {
    if (tg?.BackButton.isVisible) tg.BackButton.hide();
  }, []);

  useReloadScrollTop();

  const onSetRestaurant = (id: string) => {
    setRestaurant(id);
    localStorage.setItem('restaurantId', id);
  };

  return (
    <>
      <Section>
        <Header />
        {process.env.NODE_ENV === 'development' && (
          <Link to={'/cart'}>CART</Link>
        )}
        <S.Heading>Главная</S.Heading>
        <RestSelect restaurant={restaurant} setRestaurant={onSetRestaurant} />
      </Section>
      <S.Categories>
        {loading ? (
          <Skeleton
            highlightColor={tg?.themeParams.section_bg_color}
            baseColor={tg?.backgroundColor}
            height={68}
            width={68}
            count={10}
            borderRadius={16}
            wrapper={InlineWrapperWithMargin}
            inline
          />
        ) : data && data.categories.length ? (
          data.categories.map((category) => (
            <Category category={category!} key={category!.id} />
          ))
        ) : (
          <div>Доступных категорий нет</div>
        )}
      </S.Categories>
      <Section>
        <Positions restaurant={restaurant} isNoApp={!user} />
      </Section>
      <TopSiteLinkContainer />
    </>
  );
};

function InlineWrapperWithMargin({ children }: PropsWithChildren<unknown>) {
  return <span style={{ marginRight: '0.75rem' }}>{children}</span>;
}
