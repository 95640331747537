import { useFormInput } from '../../../hooks/useFormInput';
import * as S from './styles';
import { CustomInput } from './styles';
import { FormRadios } from './FormRadios/FormRadios';
import { deliveries, pays } from './data';
import { useCart } from '../../../hooks/useCart';
import {
  AddressSuggestions,
  DaDataAddress,
  DaDataSuggestion,
} from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { GET_RESTAURANTS } from '../../../graphql/Query';
import { formDataVar, promocodeVar } from '../../../graphql/Cache';
import { useEffect, useState } from 'react';
import { useValidation } from '../../../hooks/useValidation';
import { ErrorNotification, Relative } from '../../../styles/components';
import { Districts } from './Districts/Districts';
import { Total } from './Total/Total';
import { useTgMainBtn } from '../../../hooks/useTgMainBtn';
import { useTelegram } from '../../../hooks/useTelegram';
import { MIN_ORDER_SUM } from '../../Cart/Cart';
import { SEND_ORDER } from '../../../graphql/Mutation';
import { OrderInput } from '../../../gql/graphql';
import { Toggler } from '../../../components/Toggler/Toggler';

export type FormDataType = {
  payment: string;
  delivery: string;
  district: string;
  address: string;
  comment: string;
  deliveryAt: string;
};
type FormProps = {
  phone: string;
  address: string;
  district: string;
};

export const minDeliveryTimeInMinutes = 90;
export const MINUTE = 1000 * 60;
const isoDelta = MINUTE * 60 * 3;

export const Form = ({ phone, address, district }: FormProps) => {
  const { data } = useQuery(GET_RESTAURANTS, {
    onError: (error) => console.log(error),
  });
  const restaurant = !!localStorage.getItem('restaurantId')
    ? (localStorage.getItem('restaurantId') as string)
    : '1';
  const restData =
    data &&
    data.restaurants &&
    data.restaurants.find((r) => r!.id === restaurant);
  const stdDeliveryTime = restData
    ? MINUTE * restData.deliveryTime
    : MINUTE * minDeliveryTimeInMinutes;

  const formData = useReactiveVar(formDataVar);
  const promocode = useReactiveVar(promocodeVar);
  const isDateWasSet =
    !!formData?.deliveryAt &&
    new Date(formData.deliveryAt).getTime() > Date.now() + stdDeliveryTime;
  const [values, reset, hardSet] = useFormInput({
    payment: formData?.payment || 'bankCard',
    delivery: formData?.delivery || 'courier',
    phone,
    district: formData?.district || district || '',
    address: formData?.address || address || '',
    promocode: promocode?.code || '',
    comment: formData?.comment || '',
    deliveryAt: isDateWasSet
      ? formData.deliveryAt
      : new Date(Date.now() + stdDeliveryTime + isoDelta).toISOString(),
  });
  const [isChecked, setChecked] = useState<boolean>(!isDateWasSet);
  const [validMessages, onType] = useValidation(restData?.deliveryTime || 90);
  const [isValidAddress, setValidAddress] = useState<boolean>();

  const { cart } = useCart();
  const [send] = useMutation(SEND_ORDER, {
    onError: (err) => {
      console.log(err);
      tg?.showPopup({
        title: 'Произошла ошибка',
        message:
          'При попытке оформления заказа произошла ошибка, попробуйте оформить еще раз',
      });
    },
    onCompleted: (d) => {
      if (d.makeOrder) {
        localStorage.setItem(
          'cart',
          JSON.stringify({
            ...cart,
            [restaurant]: [],
          }),
        );
        tg?.showPopup(
          {
            title: 'Заказ успешно оформлен',
            message: 'Сообщение для оплаты отправлено в чат',
          },
          () => {
            tg?.close();
          },
        );
      }
    },
  });

  const { tg } = useTelegram();
  const { setText } = useTgMainBtn();

  const items = cart[restaurant];
  const totalPrice =
    items &&
    items.length &&
    items
      .map(({ price, count }) => price * count)
      .reduce((val, acc) => acc + val);

  const getDiscount = () => {
    // NOTE: ниже логика для добавления скидки в 15% при самовывозе
    // const deliveryPercent = values.value.delivery === 'self' ? 15 : 0;
    const deliveryPercent = 0;
    const promoPercent = !!promocode?.discount ? promocode.discount : 0;
    const deliveryDiscount = totalPrice * (deliveryPercent / 100);
    const promoDiscount = Math.min(
      promocode?.maxDiscount ?? 0,
      totalPrice * (promoPercent / 100),
    );

    return Math.max(promoDiscount, deliveryDiscount);
  };

  const discount = getDiscount();
  const total = totalPrice - discount;

  useEffect(() => {
    return () => {
      const value = {
        payment: values.value.payment,
        delivery: values.value.delivery,
        district: values.value.district,
        address: values.value.address,
        comment: values.value.comment,
        deliveryAt: '',
      };
      if (
        (!isChecked &&
          !!validMessages?.deliveryAt &&
          validMessages.deliveryAt === 'isValid') ||
        !!formData?.deliveryAt
      )
        value.deliveryAt = values.value.deliveryAt;
      formDataVar(value);
    };
  }, [values.value, validMessages]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.onChange(e);
    onType(e);
  };

  const onSend = async () => {
    if (
      (!!validMessages && !validMessages.isValid) ||
      (formData?.delivery !== 'self' && totalPrice < MIN_ORDER_SUM)
    )
      return;
    let variables: OrderInput = {
      delivery: values.value.delivery,
      deliveryAt: values.value.deliveryAt,
      payment: values.value.payment,
      phone: values.value.phone.replaceAll(/\D/g, ''),
      products: items.map((item) => ({
        id: item.id,
        count: item.count,
      })),
      restaurant,
    };
    if (values.value.promocode) variables.promocode = values.value.promocode;
    if (values.value.comment) variables.comment = values.value.comment;
    if (values.value.delivery !== 'self') {
      if (!values.value.district) return;
      if (!values.value.address) {
        setValidAddress(false);
        return;
      }
      variables.address = values.value.address;
      variables.district = values.value.district;
    }
    console.log(variables);
    await send({
      variables: {
        orderInput: variables,
      },
    });
  };

  useEffect(() => {
    if (!tg) return;
    setText('Оформить заказ');
  }, [tg]);

  useEffect(() => {
    if (!tg) return;
    tg.MainButton.onClick(onSend);
    return () => {
      tg.MainButton.offClick(onSend);
    };
  }, [tg, onSend]);

  return (
    <S.Wrapper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormRadios
          title={'Оплата'}
          items={pays}
          currentValue={values.value.payment}
          set={(type: string) => hardSet('payment', type)}
        />
        <FormRadios
          title={'Доставка'}
          items={deliveries
            .filter((d) => d.type === values.value.delivery)
            .map((del) =>
              del.type === 'self' && restData
                ? {
                    ...del,
                    description: del.description + ` (${restData.name})`,
                  }
                : del,
            )}
          currentValue={values.value.delivery}
          set={() => {}}
          // set={(type: string) => hardSet('delivery', type)}
        />
        <S.FormSection>
          <S.Title>
            Контактный телефон <S.Required>*</S.Required>
          </S.Title>

          <Relative>
            <S.PhoneWrapper>
              <PhoneInput
                country={'ru'}
                onlyCountries={['ru']}
                disableDropdown={true}
                countryCodeEditable={false}
                value={values.value.phone}
                copyNumbersOnly={true}
                autoFormat={true}
                onChange={(val, _, e) => onChange(e)}
                inputProps={{
                  name: 'phone',
                  'data-status':
                    !!validMessages &&
                    !!validMessages.phone &&
                    validMessages.phone !== 'isValid'
                      ? 'error'
                      : undefined,
                }}
              />
            </S.PhoneWrapper>
            <ErrorNotification
              $show={
                !!validMessages &&
                !!validMessages.phone &&
                validMessages.phone !== 'isValid'
              }
            >
              <div>
                {validMessages?.phone !== 'isValid' && validMessages?.phone}
              </div>
            </ErrorNotification>
          </Relative>
        </S.FormSection>
        {values.value.delivery === 'courier' && (
          <>
            <S.FormSection>
              <S.Title>
                Район доставки <S.Required>*</S.Required>
              </S.Title>
              <div style={{}}>
                <Districts
                  total={total}
                  district={values.value.district}
                  setValue={(id: string) => hardSet('district', id)}
                />
              </div>
            </S.FormSection>
            <S.FormSection>
              <S.Title>
                Адрес доставки <S.Required>*</S.Required>
              </S.Title>
              <AddressSuggestions
                token={process.env.REACT_APP_DADATA!}
                customInput={CustomInput}
                minChars={3}
                suggestionsClassName={'react-dadata__suggestions'}
                defaultQuery={values.value.address}
                onChange={(
                  val: DaDataSuggestion<DaDataAddress> | undefined,
                ) => {
                  hardSet('address', val?.value);
                  setValidAddress(undefined);
                }}
                inputProps={{
                  required: true,
                  //@ts-ignore
                  'data-status': isValidAddress === false ? 'error' : undefined,
                }}
              />
            </S.FormSection>
          </>
        )}
        <S.FormSection>
          <S.DateTitleDivider>
            <S.Title>Время доставки</S.Title>
            <Toggler
              title={'Ближайшее'}
              isChecked={isChecked}
              onChange={() => {
                hardSet(
                  'deliveryAt',
                  new Date(
                    Date.now() + stdDeliveryTime + isoDelta,
                  ).toISOString(),
                );
                setChecked((v) => !v);
              }}
              isRevert={true}
            />
          </S.DateTitleDivider>

          {!isChecked && (
            <Relative>
              <input
                name={'deliveryAt'}
                type={'datetime-local'}
                value={values.value.deliveryAt.replace(/:\d{2}\..*/, '')}
                min={new Date(Date.now() + stdDeliveryTime + isoDelta)
                  .toISOString()
                  .replace(/:\d{2}\..*/, '')}
                onChange={onChange}
                data-status={
                  !!validMessages &&
                  !!validMessages.deliveryAt &&
                  validMessages.deliveryAt !== 'isValid'
                    ? 'error'
                    : undefined
                }
              />
              <ErrorNotification
                $show={
                  !!validMessages?.deliveryAt &&
                  validMessages.deliveryAt !== 'isValid'
                }
              >
                <div>
                  {validMessages?.deliveryAt !== 'isValid' &&
                    validMessages?.deliveryAt}
                </div>
              </ErrorNotification>
            </Relative>
          )}
        </S.FormSection>
        <S.FormSection>
          <S.Title>Комментарий</S.Title>
          <input
            id={'comment'}
            name={'comment'}
            value={values.value.comment}
            onChange={values.onChange}
          />
        </S.FormSection>
      </form>
      <hr />
      <Total
        total={total}
        district={values.value.district}
        discount={discount}
        isSelf={values.value.delivery === 'self'}
      />
      {process.env.NODE_ENV !== 'production' && (
        <button onClick={onSend}>Send</button>
      )}
    </S.Wrapper>
  );
};
