import { Route, Routes } from 'react-router-dom';
import { Main } from './Main/Main';
import { Position } from './Position/Position';
import { CartPage } from './Cart/Cart';
import { OrderPage } from './Order/Order';

export const Pages = () => {
  const main = <Main />;
  const position = <Position />;
  const cart = <CartPage />;
  const order = <OrderPage />;
  const noMatch = <NoMatch />;

  return (
    <Routes>
      <Route index={true} path={'/'} element={main} />
      <Route path={'/product/:id'} element={position} />
      <Route path={'/cart'} element={cart} />
      <Route path={'/order'} element={order} />
      <Route path={'/*'} element={noMatch} />
    </Routes>
  );
};

const NoMatch = () => {
  return <div>Ошибка 404. Данная страница не существует.</div>;
};
