import { Product } from '../../../../gql/graphql';
import _ from 'lodash';
import { ProductGroup } from './ProductGroup/ProductGroup';

type ProductsProps = {
  products: Product[];
  isNoApp: boolean;
};
export const Products = ({ products, isNoApp }: ProductsProps) => {
  return _.chain(products)
    .map((prod) => ({
      ...prod,
      category: prod.category.type,
      categoryName: prod.category.name,
    }))
    .groupBy('category')
    .values()
    .value()
    .map((items, i) => (
      <li key={items[0].category} id={items[0].category}>
        <ProductGroup items={items} isFirst={i === 0} isNoApp={isNoApp} />
      </li>
    ));
};
