import { URI } from '../../index';
import dish from '../../assets/imgs/dish.webp';

type ProductImageProps = {
  src?: string;
  alt: string;
};
export const ProductImage = ({ src, alt }: ProductImageProps) => {
  return (
    <img
      src={src ? URI + 'positions/' + src : dish}
      alt={alt}
      loading={'lazy'}
      decoding={'async'}
    />
  );
};
