import { useTelegram } from './useTelegram';
import { useCallback, useRef } from 'react';

export const useTgMainBtn = () => {
  const { tg } = useTelegram();
  const callback = useRef<null | (() => void)>(null);

  const showButton = useCallback(() => {
    tg?.MainButton.show();
  }, [tg]);

  const hideButton = useCallback(() => {
    tg?.MainButton.hide();
  }, [tg]);

  const onToggleButton = useCallback(() => {
    if (tg?.MainButton.isVisible) {
      tg.MainButton.hide();
    } else {
      tg?.MainButton.show();
    }
  }, [tg?.MainButton]);

  const onToggleEnableButton = useCallback(() => {
    if (tg?.MainButton.isVisible) {
      if (tg.MainButton.isActive) {
        tg.MainButton.disable();
      } else {
        tg.MainButton.enable();
      }
    }
  }, [tg?.MainButton]);

  const setText = useCallback(
    (text: string) => {
      tg?.MainButton.setText(text);
    },
    [tg?.MainButton],
  );

  // const setCb = useCallback(
  //   (cb: () => void) => {
  //     if (cb === callback.current) return;
  //     console.log(callback.current, cb);
  //     if (callback.current) {
  //       console.log('clear cb array');
  //       tg?.MainButton.offClick(callback.current);
  //     }
  //     callback.current = cb;
  //     tg?.MainButton.onClick(callback.current);
  //   },
  //   [tg?.MainButton, callback.current],
  // );
  //
  // useEffect(() => {
  //   return () => {
  //     if (callback.current) {
  //       console.log('clear cb array from unmount');
  //       tg?.MainButton.offClick(callback.current);
  //     }
  //   };
  // }, [tg?.MainButton]);

  return {
    onToggleButton,
    onToggleEnableButton,
    setText,
    showButton,
    hideButton,
    // setCb,
  };
};
