import * as S from './styles';
import { ScreenReader } from '../../../../styles/utilites';
import { FormSection, Title } from '../styles';

type RadioItems = {
  type: string;
  title: string | JSX.Element;
  description: string;
  img?: string;
}[];
type FormRadiosProps = {
  items: RadioItems;
  currentValue: string;
  title: string;
  set: (arg: string) => void;
};

export const FormRadios = ({
  title,
  items,
  currentValue,
  set,
}: FormRadiosProps) => {
  return (
    <FormSection>
      <Title>{title}</Title>
      <div>
        {items.map(({ type, description, title, img }) => (
          <div key={type}>
            <S.Label htmlFor={type}>
              <ScreenReader>
                <input
                  type={'radio'}
                  name={'radios'}
                  id={type}
                  checked={type === currentValue}
                  onChange={() => {
                    set(type);
                  }}
                />
              </ScreenReader>
              <S.RadioBox>
                {type === currentValue && <S.RadioCircle />}
              </S.RadioBox>
              <div>
                <div>{title}</div>
                {type === currentValue && (
                  <>
                    <S.Description>{description}</S.Description>
                    {img && (
                      <S.Img
                        src={img}
                        alt={'Карта'}
                        decoding={'async'}
                        loading={'lazy'}
                      />
                    )}
                  </>
                )}
              </div>
            </S.Label>
          </div>
        ))}
      </div>
    </FormSection>
  );
};
