import styled, { css } from 'styled-components';
import { getTransition } from './utilites';

export const Section = styled.section`
  padding: 0.75rem;
`;

export const Relative = styled.div`
  position: relative;
  width: 100%;
`;

type NotificationProps = {
  $show: boolean;
  $isLight?: boolean;
};
export const Notification = styled.div<NotificationProps>`
  position: absolute;
  bottom: -1.5rem;
  font-weight: 600;
  font-size: 0.85rem;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.green};
  ${({ $isLight }) =>
    $isLight &&
    css`
      color: ${({ theme }) => theme.colors.green};
    `}

  ${getTransition(300, 'opacity')}
`;
export const ErrorNotification = styled(Notification)`
  color: var(--tg-theme-destructive-text-color);
`;
