import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Restaurant = styled.div`
  font-weight: 600;
  margin-right: 2rem;
`;
