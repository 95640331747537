import { useCallback, useContext } from 'react';
import { TelegramContext } from '../providers/Telegram';

export const useTelegram = () => {
  const { user, tg } = useContext(TelegramContext);

  const onClose = useCallback(() => {
    tg?.close();
  }, [tg]);

  return {
    user,
    tg,
    onClose,
  };
};
