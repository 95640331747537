import { useTelegram } from './useTelegram';
import { useLayoutEffect } from 'react';
import { telegramInitDataVar } from '../graphql/Cache';

export const useTgInit = () => {
  const { tg } = useTelegram();
  useLayoutEffect(() => {
    if (!tg?.isExpanded) tg?.expand();
    tg?.enableClosingConfirmation();
    if (tg?.initData) telegramInitDataVar(tg.initData);
  }, [tg]);
};
