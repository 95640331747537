import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 3;
  width: 100%;
  margin: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ButtonDivider = styled.div`
  width: 100%;
  margin: 0;
  max-width: 700px;
  background-color: var(--tg-theme-bg-color);
  padding: 0.75rem 0.5rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  box-shadow: 0 -1px 12px 5px color-mix(in srgb, var(--tg-theme-text-color), transparent
        90%);

  > a,
  > button {
    text-align: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
`;
