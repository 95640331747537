import { useQuery } from '@apollo/client';
import { GET_DISTRICTS } from '../../../../graphql/Query';
import { Selection } from '../../../../components/Selection/Selection';
import * as S from './styles';

type DistrictsProps = {
  total: number;
  district: string;
  setValue: (id: string) => void;
};
export const Districts = ({ district, setValue, total }: DistrictsProps) => {
  const { data, loading } = useQuery(GET_DISTRICTS);

  const current = data?.districts?.find((dist) => dist!.id === district);

  if (loading) return <div>Загрузка</div>;
  if (!data || !data.districts || !data.districts.length) return <div>...</div>;
  return (
    <>
      <Selection
        currentId={district}
        setValue={setValue}
        items={data.districts.map((dist) => ({
          id: dist!.id,
          content: dist!.name,
        }))}
      />
      {current && (
        <S.Info>
          {current.freeDeliveryFrom > total
            ? `Стоимость доставки: ${
                current.deliveryPrice
              } руб. До бесплатной доставки: ${
                current.freeDeliveryFrom - total
              } руб.`
            : `Стоимость доставки: бесплатно`}
        </S.Info>
      )}
    </>
  );
};
