import styled from 'styled-components';

type PriceProps = {
  $withSpaces?: boolean;
};
export const Price = styled.div<PriceProps>`
  font-size: 1.5rem;
  margin-right: ${({ $withSpaces }) => ($withSpaces ? '1.5rem' : '0.5rem')};
  font-weight: 600;
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.35rem;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.05rem;
  color: var(--hint-color);
`;
