import styled from 'styled-components';
import { Section } from '../../styles/components';

export const NotFound = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  margin: 1rem 0;
`;

export const ImageWrapper = styled.div`
  display: flex;
  max-width: 100%;
  position: relative;
  margin-bottom: 0.75rem;

  img {
    border-radius: 1.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    width: 100%;
    object-fit: cover;
    max-height: 330px;
    background-color: var(--tg-theme-bg-color);
    box-shadow: 0 2px 3px 1px
      color-mix(in srgb, var(--tg-theme-text-color), transparent 80%);
  }

  > span {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    > span {
      flex-grow: 1;
      border-radius: 1.5rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
`;

export const ContentSkeletonWrapper = styled.div`
  > span {
    width: 100%;

    > span {
      border-radius: 1.5rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
  }
`;

export const ContentWrapper = styled(Section)`
  background: var(--tg-theme-bg-color);
  box-shadow: 0 2px 3px 1px
    color-mix(in srgb, var(--tg-theme-text-color), transparent 80%);
  border-radius: 1.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-bottom: 4.5rem;

  > * + * {
    margin-bottom: 1rem;
  }
`;

export const Name = styled.div`
  margin-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
`;

export const Description = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.2;
`;

export const Title = styled.div`
  margin-top: 1rem;
  display: inline-block;
  border-bottom: 1px dashed var(--tg-theme-hint-color);
  flex-shrink: 1;
  flex-grow: 0;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 2rem;

  li {
    margin-bottom: 0.75rem;
  }
`;

export const Property = styled.span`
  text-align: left;
  width: 190px;
  position: relative;
  z-index: 2;
  display: inline-block;

  > div {
    display: inline-block;
    background-color: var(--tg-theme-bg-color);
  }

  &::after {
    content: ' ';
    display: inline-block;
    height: 0.5rem;
    width: inherit;
    position: absolute;
    right: 0;
    z-index: -1;
    bottom: 4px;
    border-bottom: 1px dashed var(--tg-theme-hint-color);
  }
`;

export const PropertyValue = styled.span`
  display: inline-block;
`;
