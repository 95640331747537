import { createGlobalStyle } from 'styled-components';
import { getTransition, scrollBar } from './utilites';

export default createGlobalStyle`
    :root {
        box-sizing: border-box;
        font-size: calc(0.8rem + 0.25vw);
        letter-spacing: 0.02rem;
    }

    html {
        font-family: Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji,
        Segoe UI Symbol;
        color: var(--tg-theme-text-color);
        background-color: var(--tg-theme-secondary-bg-color);
        display: flex;
        justify-content: center;
        //плавный сколл
        scroll-behavior: smooth;
    }

    body {
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        max-width: 700px;
        flex-grow: 1;
    }

    ${scrollBar}
    nav ol,
    nav ul {
        list-style: none;
    }

    hr {
        margin: 1rem 0;
        border: 0;
        height: 1px;
        width: 100%;
        background-color: var(--tg-theme-hint-color);
    }

    a {
        // text-decoration: none;
        color: var(--tg-theme-link-color);
        cursor: pointer;
        ${getTransition()};
    }

    [type="button"]:not(:disabled),
    [type="reset"]:not(:disabled),
    [type="submit"]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        // -webkit-box-shadow: 0 0 0 30px hsl(0, 0%, 92%) inset !important;
    }

    input:focus-visible {
        //border-color: inherit;
        //outline: none;
    }

    button {
        background: var(--tg-theme-button-color);
        color: var(--tg-theme-button-text-color);
        border: none;
        border-radius: 10px;
        padding: 0.65rem 1.5rem;
    }
    
    input {
        background: var(--tg-theme-bg-color);
        color: var(--tg-theme-text-color);
        padding: 0.85em 1em;
        max-width: 100%;
        font-size: 1rem;
        border-radius: 1em;
        border: 1px solid transparent;
        outline: none;
        &:focus-within{
            border: 1px solid var(--tg-theme-button-color)
        }
        &[data-status="error"]{
            border: 1px solid var(--tg-theme-destructive-text-color) !important;
        }
        &[data-status="success"]{
        border: 1px solid ${({ theme }) => theme.colors.green} !important;
        }
        ${getTransition(300, 'border-color')}
    }

`;
