import styled from 'styled-components';
import { getTransition } from '../../../styles/utilites';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Heading = styled.h2`
  font-size: 1.1rem;
  font-weight: bold;
`;

export const Button = styled(Link)`
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.65rem 1.5rem;
  text-decoration: none;
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  &:active {
    background: transparent;
  }
  ${getTransition()}
`;
