import { Replace } from '../../../../../types';
import { Product } from '../../../../../gql/graphql';
import { ProductCard } from './ProductCard/ProductCard';
import * as S from './styles';

export type ProductFlatten = Replace<Product, 'category', string> & {
  categoryName: string;
};
type ProductGroupProps = {
  isFirst: boolean;
  items: ProductFlatten[];
  isNoApp: boolean;
};
export const ProductGroup = ({
  items,
  isFirst,
  isNoApp,
}: ProductGroupProps) => {
  return (
    <S.Wrapper>
      <S.Heading $isFirst={isFirst}>
        {items[0].categoryName.replace(/(\S+\s+)/, '')}
      </S.Heading>
      <S.CardsContainer>
        {items.map((product) => (
          <ProductCard product={product} key={product.id} isNoApp={isNoApp} />
        ))}
      </S.CardsContainer>
    </S.Wrapper>
  );
};
